import React, { useMemo } from 'react';
import { GameContentProps, NoveltySelectProps } from '../types';
import { Radio, ComboboxV2 } from '@purposeinplay/core-v2';
import { useTranslation } from 'react-i18next';
import FlatSortSVG from 'public/assets/flat-sort.svg';
import { cn } from '@/utils/style';

const NoveltySelect = ({
  onSelect,
  selected,
  className,
}: NoveltySelectProps) => {
  const { t } = useTranslation();
  const gameCategoryContent = t('game_category') as GameContentProps;
  const sortingOptions = gameCategoryContent?.sort?.options;

  const categoriesMapped = useMemo(
    () =>
      sortingOptions?.map((item) => ({
        id: item?.key,
        trailing: (
          <Radio
            id={`radio-${item?.key}`}
            checked={item?.key === selected}
            readOnly
            className="pointer-events-none"
          />
        ),
        label: item?.value,
        value: item?.value,
      })),
    [sortingOptions, selected],
  );

  const selectedItem = useMemo(
    () => categoriesMapped?.find((item) => item?.id === selected),
    [categoriesMapped, selected],
  );

  // Get default option name when nothing is selected
  const defaultSortName = useMemo(() => {
    const defaultOption = sortingOptions?.find(
      (option) => option.key === 'popular',
    );
    return defaultOption?.value || 'Default';
  }, [sortingOptions]);

  return (
    <div className={cn(['w-full min-w-max', className])}>
      <ComboboxV2
        items={categoriesMapped}
        multi={false}
        defaultSelectedItems={selectedItem ? [selectedItem] : []}
        onSelect={onSelect}
        className="w-[calc(var(--radix-popper-anchor-width)+2rem)]"
      >
        <ComboboxV2.Trigger
          className="w-full"
          triggerTrailing={null}
          triggerLeading={<FlatSortSVG className="text-icons-subdued" />}
          placeholder={
            selectedItem
              ? `${gameCategoryContent?.sort?.select_labels['sort']}: ${selectedItem?.value}}`
              : `${gameCategoryContent?.sort?.select_labels['sort']}: ${defaultSortName}`
          }
        />
        <ComboboxV2.Content
          className="w-[calc(var(--radix-popper-anchor-width)+2rem)]"
          scrollAreaClassName="h-auto"
        >
          <ComboboxV2.Group>
            {({ filteredItems }) => {
              return filteredItems.map((item) => (
                <ComboboxV2.Option key={item.id} item={item} />
              ));
            }}
          </ComboboxV2.Group>
        </ComboboxV2.Content>
      </ComboboxV2>
    </div>
  );
};

export default NoveltySelect;
