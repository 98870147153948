import { useAppDispatch, useAppSelector } from '@/hooks/redux';
import { Input } from '@purposeinplay/core-v2';
import { setOpenSearch, setQuery, setSearchType } from '@/app/reducers/search';
import React, { useCallback, useEffect, useState } from 'react';
import MagnifySVG from 'public/assets/magnify.svg';
import { useTranslation } from '@/app/i18n/client';
import { SearchContentType } from '@/types/json-content/search';
import { usePathname, useRouter, useSearchParams } from 'next/navigation';
import useDebounce from '@/hooks/debounce';
import { createQueryString } from '@/utils/helpers';
import { SearchType } from '@/modules/search/types';
import FieldIcon from './field-icon';

const SearchTrigger: React.FC<{ onBeforeSearchOpen?: () => void }> = ({
  onBeforeSearchOpen,
}) => {
  const dispatch = useAppDispatch();
  const pathname = usePathname();
  const isSearchOpen = useAppSelector((state) => state.search.open);
  const onSearchPage = pathname?.includes('search');
  const onProvidersIndexPage = pathname === '/providers';
  const router = useRouter();
  const searchParams = useSearchParams();

  useEffect(() => {
    if (onSearchPage || onProvidersIndexPage) return;
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.metaKey && event.key === 'k' && !isSearchOpen) {
        event.preventDefault();
        onBeforeSearchOpen?.();
        dispatch(setOpenSearch(true));
        dispatch(setSearchType(SearchType.GAMES));
      }

      if (event.key === 'Escape' && isSearchOpen) {
        dispatch(setOpenSearch(false));
        dispatch(setQuery(''));
        dispatch(setSearchType(SearchType.GAMES));
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [
    dispatch,
    isSearchOpen,
    onBeforeSearchOpen,
    onProvidersIndexPage,
    onSearchPage,
  ]);

  const handleClick = useCallback(() => {
    if (onSearchPage || onProvidersIndexPage) return;
    onBeforeSearchOpen?.();
    dispatch(setOpenSearch(true));
    dispatch(setSearchType(SearchType.GAMES));
  }, [dispatch, onBeforeSearchOpen, onProvidersIndexPage, onSearchPage]);

  const { t } = useTranslation();
  const searchContent = t('search') as SearchContentType;

  const { search, games, providers } = searchContent?.input_field?.placeholder;

  const { query } = useAppSelector((state) => state.search);

  const [inputValue, setInputValue] = useState<string>(query);

  useEffect(() => {
    setInputValue(query);
  }, [query]);

  const debounce = useDebounce();

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value;

      setInputValue(value);

      debounce(() => {
        dispatch(setQuery(value));
        if (!isSearchOpen && !onSearchPage && !onProvidersIndexPage) {
          onBeforeSearchOpen?.();
          dispatch(setOpenSearch(true));
        }
      }, 500);

      if (onSearchPage || onProvidersIndexPage)
        router.push(
          `${pathname}?${createQueryString('q', value, searchParams)}`,
        );
    },
    [
      debounce,
      onSearchPage,
      onProvidersIndexPage,
      router,
      pathname,
      searchParams,
      dispatch,
      isSearchOpen,
      onBeforeSearchOpen,
    ],
  );

  return (
    <div className="relative hidden md:block">
      <Input
        id="navbar-search-trigger"
        onClick={handleClick}
        className="min-w-[20.5rem] cursor-pointer transition duration-200 ease-in-out hover:placeholder:text-text-subdued md:pl-12 md:pr-10"
        required
        value={inputValue}
        onChange={handleChange}
        type="text"
        leadingIcon={
          <MagnifySVG className="h-5 w-5 !fill-none stroke-text-subdued" />
        }
        variant="static"
        placeholder={
          !onProvidersIndexPage
            ? `${search} ${games} & ${providers}`
            : `${search} ${providers}`
        }
      />
      <FieldIcon hasCloseBtn={false} />
    </div>
  );
};

export default SearchTrigger;
