import { ScrollableTabs } from '@purposeinplay/core-v2';
import React, { useCallback, useMemo } from 'react';
import { SEARCH_CHIPS } from '../../constants';
import { useAppDispatch, useAppSelector } from '@/hooks/redux';
import { setSelectedCollection } from '@/app/reducers/search';
import { usePathname, useSearchParams } from 'next/navigation';

const FilterChips = () => {
  const dispatch = useAppDispatch();
  const searchParams = useSearchParams();
  const pathname = usePathname();
  const { selectedCollection, selectedSort } = useAppSelector(
    (state) => state.search,
  );

  const removePageHref = useCallback(
    (triggerNav?: boolean) => {
      const urlSearchParams = new URLSearchParams(searchParams?.toString());
      urlSearchParams.delete('page');
      const search = urlSearchParams.toString();
      const query = search ? `?${search}` : '';
      if (triggerNav) {
        window.history.replaceState(null, '', `${pathname}${query}`);
        return;
      }
      return `${pathname}${query}`;
    },
    [pathname, searchParams],
  );

  const handleSelectCollection = useCallback(
    (collection: any) => {
      // remove page param when changing collections
      if (searchParams?.get('page')) {
        removePageHref(true);
      }
      let gameCollection = selectedCollection?.includes('new')
        ? ['fresh-releases']
        : collection;

      if (selectedSort && selectedSort !== 'novelty') {
        return dispatch(setSelectedCollection([gameCollection, 'all']));
      }
      return dispatch(setSelectedCollection([gameCollection]));
    },
    [dispatch, removePageHref, searchParams, selectedCollection, selectedSort],
  );

  const handleTabClick = (
    e: any,
    item: {
      id: string;
      label: string;
      leadingIcon?: React.ReactNode;
      tab: string;
    },
  ) => {
    e.preventDefault();
    handleSelectCollection(item?.tab);
  };

  const defaultSelectedIndex = SEARCH_CHIPS.findIndex(
    (chip) => chip.tab === selectedCollection[0],
  );

  return (
    <ScrollableTabs
      defaultSelectedIndex={
        defaultSelectedIndex !== -1 ? defaultSelectedIndex : 0
      }
      items={SEARCH_CHIPS}
      onClick={handleTabClick}
      variant="segmented-rounded"
      tabStyleOptions={{
        containerStyles: 'hide-scroll-wrapper min-w-[30.625rem]',
        navItemStyles:
          'whitespace-nowrap [&>div:first-of-type]:!shrink-0 !p-1.5 sm:!p-2.5 mb-0 text-text-subdued',
        navStyles: 'hide-scroll-wrapper p-0 sm:p-0  bg-transparent',
      }}
    />
  );
};

export default FilterChips;
