import React from 'react';
import { useAppSelector } from '@/hooks/redux';
import EmptyState from '@/components/EmptyState';
import { useTranslation } from '@/app/i18n/client';
import { SearchContentType } from '@/types/json-content/search';
import GamesWithFilters from '@/components/GamesWithFilters';
import { setSelectedProviders } from '@/app/reducers/search';

const GameResultList: React.FC = React.memo(() => {
  const { query, selectedCollection, selectedProviders, selectedSort } =
    useAppSelector((state) => state.search);
  const { t } = useTranslation();
  const searchContent = t('search') as SearchContentType;

  return (
    <GamesWithFilters
      query={query}
      strategy={'AND'}
      onSearchPage={false}
      stylingOptions={{
        gameListContainer: 'pt-0 md:first:!pt-0',
        container: 'gap-2',
      }}
      customEmptyState={
        <EmptyState
          className="mb-4 border-b border-surface-default p-4 !py-8"
          iconName="magnify"
          title={searchContent?.no_search_results?.title}
          description={`${searchContent?.no_search_results?.description?.['1st_part']} ${`"${query}"`} ${searchContent?.no_search_results?.description?.['2nd_part']}`}
        />
      }
      sortBy={selectedSort}
      identifier={selectedCollection?.length > 0 ? selectedCollection : ['all']}
      selectedProviders={selectedProviders}
      setProvidersList={(param: any) => setSelectedProviders(param)}
    />
  );
});

GameResultList.displayName = 'GameResultList';

export default GameResultList;
