import React from 'react';
import FilterChips from './filter-chips';
import FilterTrigger from './filters-trigger';
import { useAppSelector } from '@/hooks/redux';
import { SearchType } from '../../types';

const SearchFilters = () => {
  const { searchType } = useAppSelector((state) => state.search);

  if (searchType === SearchType.PROVIDERS) return null;

  return (
    <div className="flex flex-row items-center justify-between">
      <FilterChips />
      <FilterTrigger />
    </div>
  );
};

export default SearchFilters;
