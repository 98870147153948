import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { GameProvider } from '@/types/games';
import client from '@/utils/api';
import useMarks from './marks';

const useProviders = (): UseQueryResult<GameProvider[]> => {
  return useQuery({
    queryKey: ['providers'],
    queryFn: () => client('games/providers'),
    refetchOnMount: false,
    retry: false,
  });
};

export { useProviders };
