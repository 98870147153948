import { setOpenSearch } from '@/app/reducers/search';
import { useAppDispatch, useAppSelector } from '@/hooks/redux';
import useMediaQuery, { MOBILE_BREAKPOINT } from '@/hooks/use-media-query';
import { usePathname, useSearchParams } from 'next/navigation';
import { useCallback, useEffect, useRef, useState } from 'react';

/**
 * Handles the input focus & opening the search on keyboard press
 */
export const useSearchInputHandler = () => {
  const dispatch = useAppDispatch();
  const isOpen = useAppSelector((state) => state.search.open);
  const pathname = usePathname();
  const { matchesQuery: isMobile } = useMediaQuery(MOBILE_BREAKPOINT);
  const onSearchPage = pathname?.includes('/search');
  const [isFocused, setIsFocused] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (isMobile || onSearchPage) return;

    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.metaKey && event.keyCode === 75) {
        const inputElement = document.querySelector(
          '#search-input',
        ) as HTMLInputElement;
        if (isFocused) {
          inputElement && inputElement.blur();
          setIsFocused(false);
          dispatch(setOpenSearch(false));
          return;
        }
        dispatch(setOpenSearch(true));
        setIsFocused(true);
        inputElement && inputElement.focus();
      }

      if (event.keyCode === 27) {
        dispatch(setOpenSearch(false));
        setIsFocused(false);
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [dispatch, isFocused, isMobile, onSearchPage]);

  useEffect(() => {
    if (onSearchPage) return;
    const inputRefEl = inputRef?.current;
    const inputElement = document.querySelector(
      '#search-form input',
    ) as HTMLInputElement;

    // we also need to focus the input when the flyout menu is open
    setIsFocused(true);
    inputRefEl?.focus();
    inputElement && inputElement.focus();

    return () => {
      //on unmount unfocus the input
      inputRefEl?.blur();
      inputElement && inputElement.blur();
      setIsFocused(false);
    };
  }, [onSearchPage]);

  const onFocus = useCallback(() => {
    if (onSearchPage) return;
    setIsFocused(true);
    if (isOpen) return;
    dispatch(setOpenSearch(!isOpen));
  }, [dispatch, isOpen, onSearchPage, setIsFocused]);

  const onBlur = useCallback(() => {
    if (onSearchPage) return;
    setIsFocused(false);
  }, [onSearchPage]);

  return {
    isFocused,
    setIsFocused,
    onFocus,
    onBlur,
  };
};
