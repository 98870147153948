import React from 'react';
import { useAppSelector } from '@/hooks/redux';
import EmptyState from '@/components/EmptyState';
import { SearchContentType } from '@/types/json-content/search';
import { useTranslation } from '@/app/i18n/client';

const NoSearchResults = () => {
  const query = useAppSelector((state) => state.search.query);

  const { t } = useTranslation();
  const searchContent = t('search') as SearchContentType;

  return (
    <EmptyState
      className="mb-4 border-b border-surface-default p-4 !py-8"
      iconName="magnify"
      title={searchContent?.no_search_results?.title}
      description={`${searchContent?.no_search_results?.description?.['1st_part']} ${`"${query}"`} ${searchContent?.no_search_results?.description?.['2nd_part']}`}
    />
  );
};

export default NoSearchResults;
