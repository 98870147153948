import { useState, useEffect } from 'react';

const useDebounce = () => {
  const [timer, setTimer] = useState<any>(null);

  const debounce = (callback: any, delay = 200) => {
    clearTimeout(timer);
    setTimer(setTimeout(callback, delay));
  };

  useEffect(() => {
    return () => {
      clearTimeout(timer);
    };
  }, [timer]);

  return debounce;
};

export default useDebounce;
