import { useTranslation } from '@/app/i18n/client';
import { setExpandFilter } from '@/app/reducers/search';
import { useAppDispatch, useAppSelector } from '@/hooks/redux';
import { SearchContentType } from '@/types/json-content/search';
import { IconButton } from '@purposeinplay/core-v2';
import React from 'react';

const FiltersTrigger = () => {
  const dispatch = useAppDispatch();
  const expandFilter = useAppSelector((state) => state.search.expandFilter);

  const { t } = useTranslation();
  const searchContent = t('search') as SearchContentType;

  return (
    <div className="relative flex flex-col items-end gap-2">
      <span className="pointer-events-none absolute -left-2 h-10 w-2 bg-[rgba(10,28,45,0.98)]  bg-opacity-25 md:hidden dark:bg-opacity-75" />
      <IconButton
        onClick={() => dispatch(setExpandFilter(!expandFilter))}
        iconLeft={
          <svg
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M14 7.34L2 7.33333V8.66667H14V7.34ZM2 10.6667H10V12H2V10.6667ZM14 4H2V5.34L14 5.33333V4Z" />
          </svg>
        }
        size="md"
        color="tertiary"
        stroke
      >
        {searchContent?.filters}
      </IconButton>
    </div>
  );
};

export default FiltersTrigger;
