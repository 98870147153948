'use client';
import Image, { getImageProps, ImageProps } from 'next/image';
import {
  DESKTOP_BREAKPOINT,
  MOBILE_BREAKPOINT,
  TABLET_BREAKPOINT,
  XL_BREAKPOINT,
} from '@/hooks/use-media-query';
import { useMemo } from 'react';
import useDarkMode from '@/hooks/dark';
import { BackgroundSources } from './types';
import { cn } from '@/utils/style';
import { DEFAULT_IMAGE_LOADER } from '@/utils/constants';

const AdaptiveImage = ({
  className,
  alt = 'Hero image',
  fill = true,
  priority = true,
  sizes = '100vw',
  sources,
  pictureStyles,
  ...props
}: {
  sources?: BackgroundSources;
  className?: string;
  pictureStyles?: string;
} & Partial<ImageProps>) => {
  const common = useMemo(
    () => ({ alt, fill, priority, sizes, ...props }),
    [alt, fill, priority, props, sizes],
  );
  const { darkMode } = useDarkMode();

  const desktop = useMemo(() => {
    if (!sources?.desktop) return;

    const { srcSet: desktop, ...rest } = getImageProps({
      ...common,
      src: darkMode
        ? sources?.desktop?.dark
        : (sources?.desktop?.light ?? sources?.desktop?.dark),
    }).props;

    return {
      rest: { ...rest },
      desktop,
    };
  }, [common, darkMode, sources?.desktop]);
  const tablet = useMemo(() => {
    if (!sources?.tablet) return;
    const { srcSet: tablet } = getImageProps({
      ...common,
      src: darkMode
        ? sources?.tablet?.dark
        : (sources?.tablet?.light ?? sources?.tablet?.dark),
    }).props;

    return tablet;
  }, [common, darkMode, sources?.tablet]);

  const mobile = useMemo(() => {
    if (!sources?.mobile) return;
    const { srcSet: mobile, ...rest } = getImageProps({
      ...common,
      src: darkMode
        ? sources?.mobile?.dark
        : (sources?.mobile?.light ?? sources?.mobile?.dark),
    }).props;

    return mobile;
  }, [common, darkMode, sources?.mobile]);

  const wide = useMemo(() => {
    if (!sources?.wide) return;
    const { srcSet: wide, ...rest } = getImageProps({
      ...common,
      src: darkMode
        ? sources?.wide?.dark
        : (sources?.wide?.light ?? sources?.wide?.dark),
    }).props;

    return wide;
  }, [common, darkMode, sources?.wide]);

  // Needed for image warning to not show up (width or height modified warning)
  const { width, height } = props;
  const imageStyle = useMemo(() => {
    const style = {} as React.CSSProperties;
    if (width) style.width = `${width}px`;
    if (height) style.height = `${height}px`;
    return style;
  }, [width, height]);

  return (
    <>
      <picture className={pictureStyles}>
        <source media={XL_BREAKPOINT} srcSet={wide} />
        <source media={DESKTOP_BREAKPOINT} srcSet={desktop?.desktop} />
        <source media={MOBILE_BREAKPOINT} srcSet={mobile} />
        <source media={TABLET_BREAKPOINT} srcSet={tablet} />
        <Image
          alt={alt}
          {...(desktop?.rest as any)}
          src={desktop?.desktop}
          fill={fill ?? undefined}
          className={className}
          {...props}
          {...(width && height ? { style: imageStyle } : {})}
        />
      </picture>
    </>
  );
};

export default AdaptiveImage;
