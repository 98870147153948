import { getIconByCategory } from '@/utils/helpers';
import { Radio } from '@purposeinplay/core-v2';

export const SEARCH_CHIPS = [
  {
    id: 'chip-all',
    label: 'All Games',
    leadingIcon: getIconByCategory('all_games', 'small'),
    tab: 'all',
  },
  {
    id: 'chip-slots',
    label: 'Slots',
    leadingIcon: getIconByCategory('slots', 'small'),
    tab: 'slots',
  },
  {
    id: 'chip-live',
    label: 'Live Casino',
    leadingIcon: getIconByCategory('live', 'small'),
    tab: 'live-casino',
  },
  {
    id: 'chip-table-games',
    label: 'Table Games',
    leadingIcon: getIconByCategory('table-games', 'small'),
    tab: 'table-games',
  },
  {
    id: 'chip-roulette',
    label: 'Roulette',
    leadingIcon: getIconByCategory('roulette', 'small'),
    tab: 'roulette',
  },
  {
    id: 'chip-bonus-buy',
    label: 'Bonus Buy',
    leadingIcon: getIconByCategory('bonus-buy', 'small'),
    tab: 'bonus-buy',
  },
  {
    id: 'chip-latest',
    label: 'Latest',
    leadingIcon: getIconByCategory('latest', 'small'),
    tab: 'new',
  },
];
