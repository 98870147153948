import React from 'react';
import { Text } from '@purposeinplay/core-v2';
import { GameContentProps } from '@/modules/category-page/types';
import { useTranslation } from 'react-i18next';

const GamesWithFiltersFooter = ({
  loadedCount,
  totalCount,
}: {
  loadedCount: number;
  totalCount: number;
}) => {
  const { t } = useTranslation();
  const gameCategoryContent = t('game_category') as GameContentProps;

  return (
    <div className="pt-10 text-center">
      <div className="mx-auto">
        <div className="relative mx-auto mb-2 h-0.5  w-52 overflow-hidden bg-surface-default">
          <span
            style={{ width: `${(loadedCount / totalCount) * 100}%` }}
            className="absolute left-0 top-0 h-0.5 w-full bg-green-500"
          />
        </div>
        <Text size="sm" className="text-text-subdued">
          {gameCategoryContent?.accordion_text?.you_viewed} {loadedCount}{' '}
          {gameCategoryContent?.accordion_text?.out_of} {totalCount}{' '}
          {gameCategoryContent?.accordion_text?.games}
        </Text>
      </div>
    </div>
  );
};

export default GamesWithFiltersFooter;
