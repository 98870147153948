'use client';
import {
  setExpandFilter,
  setOpenSearch,
  setQuery,
  setResults,
  setSelectedCollection,
  setSelectedProviders,
} from '@/app/reducers/search';
import { useAppDispatch, useAppSelector } from '@/hooks/redux';
import { Modal } from '@purposeinplay/core-v2';
import React from 'react';
import ModalTitle from './title';
import SearchWrapper from './search-wrapper';

const SearchModal = () => {
  const isOpen = useAppSelector((state) => state.search.open);
  const dispatch = useAppDispatch();

  return (
    <Modal
      size="7xl"
      isOpen={isOpen}
      modalStylingOptions={{
        wrapperStyles: 'md:p-0 lg:p-0 p-3 !pb-0 md:px-4 lg:px-7 search-modal',
        containerStyles:
          'md:max-w-6xl 2xl:max-w-8xl bg-transparent shadow-none text-center rounded-none',
        overlayStyles: 'bg-[rgba(10,28,45,0.98)] pointer-events-none',
        contentStyles: 'p-0 md:p-0 lg:p-0',
        closeBtnContainerStyles: 'top-0.5 right-0 md:top-0 md:right-0',
        titleStyles:
          'text-center p-0 md:p-0 lg:p-0 mb-spacing-md lg:mb-spacing-lg',
      }}
      title={<ModalTitle />}
      preventClose
      setIsOpen={(param: boolean) => {
        if (param === false) {
          dispatch(setOpenSearch(false));
          dispatch(
            setResults({
              pages: [],
              pageParams: [],
            }),
          );
          dispatch(setQuery(''));
          dispatch(setSelectedCollection([]));
          dispatch(setSelectedProviders([]));
          dispatch(setExpandFilter(false));
          return;
        }
        dispatch(setOpenSearch(param));
      }}
    >
      <div className="pointer-events-none fixed inset-0 z-[-1] h-screen w-screen" />
      <SearchWrapper />
    </Modal>
  );
};

export default SearchModal;
