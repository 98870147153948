import Image from 'next/image';
import { useIsMounted } from 'usehooks-ts';
import { DEFAULT_IMAGE_LOADER } from '@/utils/constants';

export const ProviderImage: React.FC<{
  providerId: string;
  forModal?: boolean;
  onError: () => void;
}> = ({ providerId, forModal, onError }) => {
  const isMounted = useIsMounted();
  return (
    <Image
      loader={DEFAULT_IMAGE_LOADER}
      data-testid={`image-${providerId}`}
      loading="lazy"
      width={forModal ? 72 : 132}
      height={forModal ? 13 : 32}
      alt={isMounted() ? `Provider card for ${providerId}` : ''}
      src={`assets/brands/white/${providerId}.svg`}
      onError={onError}
      className="h-full object-contain -indent-100 opacity-50 invert dark:opacity-100 dark:invert-0"
    />
  );
};
