import { useMemo } from 'react';
import { Game } from '@/types/games';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import client from '../../utils/api';
import { useCurrency } from '../currencies';
import { getRandomFromArray } from '@/utils/helpers';
import { isMobile } from 'mobile-device-detect';
import { useGamesByIds } from './filters';
import { useGamesWithFilters } from './use-games-with-filters';
import { useCurrencyFormatter } from '@/hooks/use-currency-formatter-wrapper';
import { normalizeFilteredGames } from './utils';

const fetchRecentPlayerGames = async () => {
  const games = await client('player/games');
  const filteredArr = games.reduce((acc: any[], current: any) => {
    const x = acc.find((item: any) => item.game === current.game);
    if (!x) {
      return acc.concat([current]);
    } else {
      return acc;
    }
  }, []);
  const sortedArr = filteredArr.sort(
    (a: any, b: any) => b.created_at - a.created_at,
  );

  return sortedArr;
};

export const fetchDesktop = async () => {
  return await client('games/allowed_desktop');
};

export const useGamesByCurrency = (currency: string): Game[] => {
  const platform = isMobile ? 'mobile' : 'desktop';

  const { data: games } = useGamesWithFilters({
    page: 1,
    page_size: 100,
    device: platform,
    filter: {
      currencies: [currency],
    },
    sort: {
      type: 'novelty',
      direction: 'ASC',
      currency: currency,
    },
  });

  const randomGames = useMemo(
    () => getRandomFromArray(games?.pages[0].data, 4),
    [games?.pages],
  );

  return randomGames;
};

const useRecentGames = () => {
  return useQuery({
    queryKey: ['recentPlayerGames'],
    queryFn: async () => await fetchRecentPlayerGames(),
  });
};

const useGameDescription = (identifier: string): UseQueryResult => {
  return useQuery({
    queryKey: ['gameDescription', identifier],
    queryFn: () => client(`games/descriptions/${identifier}`),
  });
};

const useGameInfoById = (identifier: string): UseQueryResult => {
  return useQuery({
    queryKey: ['gameInfoById', identifier],
    queryFn: () => client(`games_info/${identifier}`),
  });
};

const useGamesInfo = (): UseQueryResult => {
  return useQuery({
    queryKey: ['gamesInfo'],
    queryFn: () => client(`games_info/allowed`),
    retry: false,
    refetchOnMount: false,
    staleTime: 3600000,
  });
};

const useGamesLimits = (): UseQueryResult => {
  return useQuery({
    queryKey: ['gamesLimits'],
    queryFn: () => client(`games/limits`),
  });
};

const useGameLimits = (game: Game) => {
  const { data: gamesLimits = {} }: any = useGamesLimits();
  const { formatCurrency } = useCurrencyFormatter();
  const currency = useCurrency();
  const gameLimit = useMemo(
    () => gamesLimits && gamesLimits[game?.identifier],
    [gamesLimits, game?.identifier],
  );
  const limitByCurrency = useMemo(
    () => gameLimit && currency && gameLimit[currency],
    [gameLimit, currency],
  );
  const minBet = useMemo(
    () =>
      limitByCurrency?.min_bet &&
      formatCurrency(limitByCurrency.min_bet, currency?.code, {
        style: 'decimal',
      }),
    [currency?.code, formatCurrency, limitByCurrency.min_bet],
  );

  const maxBet = useMemo(
    () =>
      limitByCurrency?.max_bet &&
      formatCurrency(limitByCurrency.max_bet, currency?.code, {
        style: 'decimal',
      }),
    [currency?.code, formatCurrency, limitByCurrency.max_bet],
  );

  return {
    minBet,
    maxBet,
  };
};

export const useRecentGamesData = () => {
  const { data: recentGames = [], isLoading } = useRecentGames();
  const ids = useMemo(
    () => recentGames?.map((game: any) => game?.game),
    [recentGames],
  );
  const recentGamesIdentifiers = useMemo(() => {
    return recentGames?.length > 0 ? Array.from(new Set(ids)) : [];
  }, [recentGames, ids]);

  const { data: completeGames, isLoading: completeGamesLoading } =
    useGamesByIds(
      {
        game_ids: recentGamesIdentifiers as string[],
      },
      {
        enabled: recentGamesIdentifiers?.length > 0,
      },
    );

  const completeGamesIdsSet = useMemo(() => {
    return completeGames?.length > 0
      ? completeGames.map((game: any) => game.id)
      : [];
  }, [completeGames]);

  const areLoading = isLoading || completeGamesLoading;

  return {
    recentGamesIdentifiers,
    recentGameIdsSet: completeGamesIdsSet,
    recentLoading: areLoading,
    completeGames,
    completeGamesLoading,
  };
};

export {
  useRecentGames,
  useGameDescription,
  useGamesInfo,
  useGamesLimits,
  useGameLimits,
  useGameInfoById,
};
