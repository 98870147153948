import { isMacOs, isWindows, isMobile, isTablet } from 'mobile-device-detect';

export const getUserPlatform = () => {
  return {
    isMac: isMacOs,
    isMobile,
    isTablet,
    isWindows,
  };
};
