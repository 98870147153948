import { Badge } from '@purposeinplay/core-v2';

export const ProviderBadge: React.FC<{
  count: number;
  forModal: boolean | undefined;
}> = ({ count, forModal = false }) => {
  const shouldDisplayBadge = count !== undefined;

  if (!shouldDisplayBadge) {
    return null;
  }

  return (
    <Badge
      data-testid="provider-badge"
      type="success"
      radius="rounded"
      className="mt-1"
      ghost
      size="md"
    >
      {count} Games
    </Badge>
  );
};
