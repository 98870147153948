import React from 'react';
import Link from 'next/link';
import { sortProvidersById } from '@/components/Providers/utils';
import { GameProvider } from '@/types/games';
import ProviderCard from '@/modules/providers/components/providers-card';
import { useAppDispatch, useAppSelector } from '@/hooks/redux';
import { setOpenSearch, setQuery, setResults } from '@/app/reducers/search';

const ProvidersList = React.memo(
  ({ filteredProviders }: { filteredProviders: GameProvider[] }) => {
    const searchOpen = useAppSelector((state) => state.search.open);
    const dispatch = useAppDispatch();

    if (!filteredProviders || filteredProviders.length === 0) return null;

    return filteredProviders.sort(sortProvidersById).map((provider: any) => (
      <Link
        key={provider?.id}
        prefetch={false}
        onClick={() => {
          if (searchOpen) {
            dispatch(setOpenSearch(false));
            dispatch(
              setResults({
                pages: [],
                pageParams: [],
              }),
            );
            dispatch(setQuery(''));
            return;
          }
        }}
        href={`/providers/${provider.id}`}
      >
        <ProviderCard provider={provider} />
      </Link>
    ));
  },
);

ProvidersList.displayName = 'ProvidersList';

export default ProvidersList;
