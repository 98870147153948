import React, { useCallback, useMemo } from 'react';
import { ComboboxV2 } from '@purposeinplay/core-v2';
import { useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import { GameContentProps, ProviderSelectProps } from '../types';
import { cn } from '@/utils/style';
import { usePathname, useSearchParams } from 'next/navigation';
import FlatProvidersSVG from 'public/assets/flat-providers.svg';

const ProviderSelect = ({
  providers,
  selectedItems,
  onSelect,
  className,
}: ProviderSelectProps) => {
  const queryClient = useQueryClient();
  const searchParams = useSearchParams();
  const pathname = usePathname();
  const { t } = useTranslation();
  const gameCategoryContent = t('game_category') as GameContentProps;

  const providersMapped = useMemo(() => {
    return providers
      ?.map((item: any) => ({
        id: item.id ?? '',
        label: item?.value || 'Unknown',
        value: item.value ?? '',
      }))
      .sort((a: any, b: any) => a.id.localeCompare(b.id));
  }, [providers]);

  const removePageHref = useCallback(() => {
    const urlSearchParams = new URLSearchParams(searchParams?.toString());
    urlSearchParams.delete('page');
    const search = urlSearchParams.toString();
    const query = search ? `?${search}` : '';
    window.history.replaceState(null, '', `${pathname}${query}`);
  }, [pathname, searchParams]);

  return (
    <div className={cn(['w-full min-w-max', className])}>
      <ComboboxV2
        multi
        items={providersMapped}
        onSelect={(item) => {
          // remove page param whenever we change the providers list
          if (searchParams?.get('page')) {
            removePageHref();
            // race condition with provider select & page param, need a small delay
            setTimeout(() => onSelect && onSelect(item), 500);
            queryClient.resetQueries({ queryKey: ['infiniteFilteredGames'] });
            return;
          }
          onSelect && onSelect(item);
        }}
        className="w-[calc(var(--radix-popper-anchor-width)+4rem)]"
        defaultSelectedItems={selectedItems}
      >
        <ComboboxV2.Trigger
          className="w-full"
          multiDisplayMode="count"
          triggerTrailing={null}
          triggerLeading={<FlatProvidersSVG className="text-icons-subdued" />}
          placeholder={gameCategoryContent?.sort?.select_labels['provider']}
        />
        <ComboboxV2.Content className="w-[calc(var(--radix-popper-anchor-width)+4rem)]">
          <ComboboxV2.Search placeholder="Search providers..." />
          <ComboboxV2.Group>
            {({ filteredItems }) =>
              filteredItems.map((item) => (
                <ComboboxV2.Option key={item.id} item={item} />
              ))
            }
          </ComboboxV2.Group>
        </ComboboxV2.Content>
      </ComboboxV2>
    </div>
  );
};

export default ProviderSelect;
