import GamesList from '@/components/Games/games-list';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useGamesWithFilters } from '@/hooks/games/use-games-with-filters';
import GamesWithFiltersFooter from './components/games-with-filters-footer';
import { NoResults } from '../Games/no-results';
import { GamesScheletonLoader } from '../Loaders';
import { GamesWithFiltersProps } from './types';
import {
  useParams,
  usePathname,
  useRouter,
  useSearchParams,
} from 'next/navigation';
import { useAppDispatch, useAppSelector } from '@/hooks/redux';
import { setResultActions } from '@/app/reducers/search';
import { useTranslation } from '@/app/i18n/client';
import { Button } from '@purposeinplay/core-v2';
import { createQueryString } from '@/hooks/modal';
import { GamesContentType } from '@/types/json-content/games';
import Link from 'next/link';
import { useIsMobile } from '@/modules/home/context/is-mobile';
import { cn } from '@/utils/style';
import { GameContentProps } from '@/modules/category-page/types';

const GamesWithFilters: React.FC<GamesWithFiltersProps> = ({
  sortBy,
  selectedProviders,
  setProvidersList,
  identifier,
  selectedCollection,
  strategy,
  gameData,
  testId,
  onSearchPage = false,
  setTotalCount,
  customEmptyState,
  stylingOptions,
  query,
  showRestrictedGames,
}) => {
  const searchParams = useSearchParams();
  const router = useRouter();
  const pathname = usePathname();
  const params = useParams();
  const dispatch = useAppDispatch();
  const { resultActions } = useAppSelector((state) => state.search);
  const currentPage = useSearchParams()?.get('page');
  const initialPage = useRef(currentPage);
  const isUserAgentMobile = useIsMobile();
  const searchOpen = useAppSelector((state) => state.search.open);

  const localIdentifier = useMemo(() => {
    const identifierMap: Record<string, string[]> = {
      popular: ['top-games'],
      new: ['fresh-releases'],
      'bonus-buy-in': ['feature-buy'],
    };

    return identifierMap[identifier as string] || identifier;
  }, [identifier]);

  const { t } = useTranslation();
  const gameCategoryContent = t('game_category') as GameContentProps;
  const gamesContent = t('games') as GamesContentType;
  const filterPayload = useMemo(() => {
    if (gameData) return;
    let sortObj: any;
    if (selectedCollection) {
      sortObj = {
        type: sortBy,
        direction: 'ASC',
      };
    } else if (params?.collection || searchParams?.get('tab')) {
      sortObj =
        sortBy !== 'novelty'
          ? {
              type: sortBy,
              direction: 'ASC',
            }
          : undefined;
    } else {
      sortObj = {
        type: sortBy || 'novelty',
        direction: 'ASC',
      };
    }

    let payload: any = {
      without_territorial_restrictions: showRestrictedGames,
      device: isUserAgentMobile ? 'mobile' : 'desktop',
      page: currentPage ? Number(currentPage) : initialPage?.current || '1',
      page_size: isUserAgentMobile ? 9 : 32,
      filter: {
        providers: selectedProviders,
      },
      sort: sortObj,
    };
    if (strategy) {
      payload.filter.categories = {
        identifiers: localIdentifier,
        strategy: 'AND',
        ...(payload.filter.categories || {}),
      };
    }

    if (query && query?.length > 0) {
      payload.filter.title = query;
    }

    if (params?.collection) {
      payload.filter.categories = {
        identifiers: localIdentifier,
        strategy: 'AND',
        ...(payload.filter.categories || {}),
      };
    } else if (identifier && identifier.length > 0) {
      payload.filter.categories = {
        identifiers: localIdentifier,
        ...(payload.filter.categories || {}),
      };
    }

    return payload;
  }, [
    gameData,
    params?.collection,
    isUserAgentMobile,
    currentPage,
    initialPage,
    selectedProviders,
    selectedCollection,
    strategy,
    query,
    identifier,
    searchParams,
    sortBy,
    localIdentifier,
    showRestrictedGames,
  ]);
  const {
    data: filterData,
    isLoading: filterIsLoading,
    isFetching: filterIsFetching,
    isFetchingNextPage: filterIsFetchingNext,
    isFetchingPreviousPage: filterIsFetchingPrev,
    fetchPreviousPage: filterFetchPrev,
    fetchNextPage: filterFetchNext,
    hasNextPage: filterHasNextPage,
    hasPreviousPage: filterHasPrevPage,
  } =
    // if gameData we don't want to enable internal fetching, that means fetching is happening outside
    useGamesWithFilters(filterPayload, {
      enabled: !!filterPayload && !gameData,
    });

  const [resultsNone, setResultsNone] = useState(false);
  const currentPageNum = Number(currentPage) || 1;

  const responseData = useMemo(() => {
    if (gameData) {
      return gameData;
    } else {
      return {
        data: filterData,
        isFetching: filterIsFetching,
        fetchNextPage: filterFetchNext,
        fetchPrevPage: filterFetchPrev,
        isFetchingNextPage: filterIsFetchingNext,
        isFetchingPrevPage: filterIsFetchingPrev,
        isLoading: filterIsLoading,
        hasNextPage: filterHasNextPage,
        hasPrevPage: filterHasPrevPage,
      };
    }
  }, [
    gameData,
    filterData,
    filterIsFetching,
    filterFetchNext,
    filterFetchPrev,
    filterIsFetchingNext,
    filterIsFetchingPrev,
    filterIsLoading,
    filterHasNextPage,
    filterHasPrevPage,
  ]);

  useEffect(() => {
    if (filterIsLoading || !setTotalCount) return;
    const totalCount =
      responseData?.data?.pages[0]?.pagination?.total_count || 0;
    setTotalCount(totalCount);
  }, [filterData, filterIsLoading, responseData?.data?.pages, setTotalCount]);

  const removePageHref = useCallback(
    (triggerNav?: boolean) => {
      const urlSearchParams = new URLSearchParams(searchParams?.toString());
      urlSearchParams.delete('page');
      const search = urlSearchParams.toString();
      const query = search ? `?${search}` : '';
      if (triggerNav) {
        window.history.replaceState(null, '', `${pathname}${query}`);
        return;
      }
      return `${pathname}${query}`;
    },
    [pathname, searchParams],
  );

  useEffect(() => {
    if (!currentPage && initialPage?.current !== '1') {
      initialPage.current = '1';
    }
  }, [currentPage]);

  useEffect(() => {
    setResultsNone(false);
  }, [selectedProviders]);

  const totalCount = responseData?.data?.pages[0]?.pagination?.total_count || 0;

  const loadedCount = useMemo(() => {
    const pageSize = isUserAgentMobile ? 9 : 32;

    const highestPage = Math.max(
      ...(responseData?.data?.pages?.map(
        (page: any) => page?.pagination?.current_page || 1,
      ) || [currentPageNum]),
    );

    const calculatedCount =
      pageSize * (currentPageNum > highestPage ? currentPageNum : highestPage);

    return Math.min(calculatedCount, totalCount);
  }, [
    isUserAgentMobile,
    currentPageNum,
    totalCount,
    responseData?.data?.pages,
  ]);
  const percentage = (loadedCount / totalCount) * 100;

  const shouldRenderFooter =
    !isNaN(loadedCount) &&
    !isNaN(totalCount) &&
    totalCount > 0 &&
    !responseData?.isLoading &&
    identifier;

  const footerContent = useMemo(() => {
    return (
      shouldRenderFooter && (
        <GamesWithFiltersFooter
          loadedCount={loadedCount}
          totalCount={totalCount}
        />
      )
    );
  }, [shouldRenderFooter, loadedCount, totalCount]);

  const isDataEmpty = responseData?.data?.pages[0]?.data?.length === 0;

  const gameListPages = useMemo(() => {
    if (!responseData?.data?.pages) return [];

    return responseData.data.pages
      .reduce((acc: any[], page: any) => {
        const pageExists = acc.some(
          (p) => p.pagination?.current_page === page.pagination?.current_page,
        );
        if (!pageExists) {
          acc.push(page);
        }
        return acc;
      }, [])
      .sort(
        (a: any, b: any) =>
          (a.pagination?.current_page || 0) - (b.pagination?.current_page || 0),
      );
  }, [responseData?.data?.pages]);

  const bodyContent = useMemo(() => {
    if (filterIsLoading) {
      return (
        <div className="pt-spacing-sm lg:pt-spacing-lg">
          <GamesScheletonLoader />
        </div>
      );
    }
    if (totalCount === 0 && !filterIsFetching) {
      if (customEmptyState) {
        return customEmptyState;
      }
      return (
        <NoResults
          onSearchPage={onSearchPage}
          className="mt-spacing-sm rounded-2xl border border-dashed border-border-general-default lg:mt-spacing-lg lg:py-40"
          header={gameCategoryContent?.no_results?.title}
          text={gameCategoryContent?.no_results?.description}
          setResultsNone={setResultsNone}
          setSelectedProviders={setProvidersList}
          noReset={isDataEmpty ? true : false}
        />
      );
    }
    return (
      <GamesList
        testId={testId}
        className={stylingOptions?.gameListContainer}
        pages={gameListPages}
      />
    );
  }, [
    filterIsFetching,
    filterIsLoading,
    gameCategoryContent?.no_results?.description,
    gameCategoryContent?.no_results?.title,
    gameListPages,
    isDataEmpty,
    onSearchPage,
    setProvidersList,
    testId,
    totalCount,
    customEmptyState,
    stylingOptions,
  ]);

  const isDisclosureButtonDisabled =
    percentage === 100 ||
    resultsNone ||
    totalCount === 0 ||
    responseData?.isFetching;
  const nextPage =
    responseData?.data?.pages[
      responseData?.data?.pages?.length - 1
    ]?.pagination?.next_page?.toString() || '1';
  const prevPage =
    responseData?.data?.pages[0]?.pagination?.prev_page?.toString() || '0';

  const getHref = useCallback(
    (name?: string, value?: string) => {
      const urlSearchParams = new URLSearchParams(searchParams.toString());
      if (name && value) {
        urlSearchParams.set(name, value);
      }
      const search = urlSearchParams.toString();
      const query = search ? `?${search}` : '';
      return `${pathname}${query}`;
    },
    [pathname, searchParams],
  );

  const handleClick = useCallback(
    async (e: any) => {
      // If `fetchNextPage` is a boolean, update state and modify URL for the next page
      if (typeof responseData?.fetchNextPage === 'boolean') {
        dispatch(setResultActions({ ...resultActions, fetchNextPage: true }));
        return;
      }

      try {
        await responseData?.fetchNextPage();
        // Update URL after successful fetch
        const newUrl = getHref('page', nextPage);
        window.history.pushState(null, '', newUrl);
      } catch (error) {
        console.error('Error fetching next page:', error);
      }
    },
    [dispatch, responseData, resultActions, getHref, nextPage],
  );

  return (
    <div className={cn(['flex flex-col', stylingOptions?.container])}>
      {responseData?.hasPrevPage && (
        <Link
          href={
            prevPage === '1'
              ? (removePageHref() as string)
              : getHref('page', prevPage)
          }
          passHref
          prefetch={false}
          className="mx-auto pt-3"
        >
          <Button
            className="mx-auto max-w-max"
            linkHref={`?${createQueryString('page', prevPage)}`}
            color="tertiary"
            isLoading={
              responseData?.isFetchingPrevPage || gameData?.isFetchingPrevPage
            }
            disabled={
              responseData?.isFetchingPrevPage || gameData?.isFetchingPrevPage
            }
            onClick={async () => {
              if (onSearchPage && gameData) {
                dispatch(
                  setResultActions({
                    ...resultActions,
                    fetchPrevPage: true,
                  }),
                );
                return;
              }
              try {
                await responseData?.fetchPrevPage();
                // Update URL after successful fetch
                const newUrl =
                  prevPage === '1'
                    ? (removePageHref() as string)
                    : getHref('page', prevPage);
                window.history.pushState(null, '', newUrl);
              } catch (error) {
                console.error('Error fetching previous page:', error);
              }
            }}
          >
            {gamesContent?.load_previous}
          </Button>
        </Link>
      )}
      {bodyContent}
      <div className="mb-7">{footerContent}</div>
      {!responseData?.hasNextPage ? null : searchOpen ? (
        <Button
          color="tertiary"
          isLoading={isDisclosureButtonDisabled}
          disabled={isDisclosureButtonDisabled}
          onClick={handleClick}
          className="mx-auto max-w-max"
        >
          {gamesContent?.load_more}
        </Button>
      ) : (
        <Link
          href={getHref('page', nextPage)}
          passHref
          scroll={false}
          prefetch={false}
          className="mx-auto"
        >
          <Button
            color="tertiary"
            linkHref={`?${createQueryString('page', nextPage)}`}
            isLoading={isDisclosureButtonDisabled}
            disabled={isDisclosureButtonDisabled}
            onClick={handleClick}
          >
            {gamesContent?.load_more}
          </Button>
        </Link>
      )}
    </div>
  );
};

export default GamesWithFilters;
