import React, { useState } from 'react';
import { ProviderNameFallback } from './provider-name-fallback';
import { ProviderImage } from './providers-image';
import { ProviderCardProps } from '@/modules/providers/types';

const ProviderCardBody: React.FC<ProviderCardProps> = ({
  provider,
  forModal,
}) => {
  const [imageError, setImageError] = useState(false);

  if (imageError) {
    return <ProviderNameFallback name={provider?.id} forModal={forModal} />;
  }

  return (
    <ProviderImage
      providerId={provider?.id}
      forModal={forModal}
      onError={() => setImageError(true)}
    />
  );
};

export default ProviderCardBody;
