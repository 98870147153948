import React from 'react';
import GameResultList from './game-result-list';
import { IsMobileProvider } from '@/modules/home/context/is-mobile';

const GamesResults = () => {
  return (
    <IsMobileProvider>
      <div className="mb-spacing-md lg:mb-spacing-lg">
        <GameResultList />
      </div>
    </IsMobileProvider>
  );
};

export default GamesResults;
