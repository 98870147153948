import { Text } from '@purposeinplay/core-v2';
import React from 'react';
import { getModalTitleBySearchType } from '../utils';
import { useAppSelector } from '@/hooks/redux';
import { useTranslation } from '@/app/i18n/client';
import { SearchContentType } from '@/types/json-content/search';

const ModalTitle: React.FC = () => {
  const { t } = useTranslation();
  const searchContent = t('search') as SearchContentType;
  const searchType = useAppSelector((state) => state.search.searchType);

  return (
    <div className="py-1.5 md:py-0">
      <Text as="span" className="font-bold">
        {searchContent?.input_field?.placeholder?.search}{' '}
        {getModalTitleBySearchType(searchType)}
      </Text>
    </div>
  );
};

export default ModalTitle;
