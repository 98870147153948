'use client';
import React from 'react';
import { Button } from '@purposeinplay/core-v2';
import Link from 'next/link';
import { NoResultsProps } from './types';
import { usePathname } from 'next/navigation';
import EmptyState from '../EmptyState';
import { useTranslation } from 'react-i18next';
import { cn } from '@/utils/style';
import { NoSearchResults } from '@/modules/search';
import { GameContentProps } from '@/modules/category-page/types';

export const NoResults = React.memo(
  ({
    setSelectedProviders,
    setResultsNone,
    noButtons = false,
    header = 'No results',
    text = 'Your search returned no results',
    noReset = false,
    onSearchPage = false,
    className,
  }: NoResultsProps) => {
    const pathname = usePathname();
    const { t } = useTranslation();
    const gameCategoryContent = t('game_category') as GameContentProps;
    return (
      <div
        className={cn([
          'flex h-full w-full flex-col items-center justify-center py-20',
          className,
        ])}
      >
        {onSearchPage ? (
          <NoSearchResults />
        ) : (
          <EmptyState
            iconName={onSearchPage ? 'magnify' : 'cards'}
            title={header}
            description={text}
            className="mb-7 border-none !py-0"
          />
        )}

        {!noButtons && (
          <div className="flex  items-center justify-between space-x-2">
            {!noReset && (
              <Button
                className="w-full"
                onClick={() => {
                  setSelectedProviders && setSelectedProviders([]);
                  setResultsNone && setResultsNone(false);
                }}
              >
                {gameCategoryContent?.no_results?.reset_filters}
              </Button>
            )}
            {!pathname?.includes('games') && (
              <Link
                tabIndex={0}
                className="link-button link-button--tertiary w-full whitespace-nowrap"
                href={`/games`}
              >
                {gameCategoryContent?.no_results?.browse_games}
              </Link>
            )}
          </div>
        )}
      </div>
    );
  },
);

NoResults.displayName = 'NoGameResults';
