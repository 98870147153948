import { useProviders } from '@/hooks/providers';
import { matchSorter } from 'match-sorter';
import React, { useMemo } from 'react';
import EmptyState from '@/components/EmptyState';
import { cn } from '@/utils/style';
import { DivLoader, ProviderListLoader } from '@/components/Loaders';
import FilteredProvidersList from './providers-list';
import { useTranslation } from '@/app/i18n/client';
import { SearchContentType } from '@/types/json-content/search';
import { useAppSelector } from '@/hooks/redux';

const ProvidersResults = () => {
  const { data: providers = [], isFetching } = useProviders();
  const query = useAppSelector((state) => state.search.query);
  const filteredProviders = useMemo(() => {
    return query
      ? matchSorter(providers, query, {
          keys: [(item: any) => item?.title?.split(' ')[0]], // Use only the first word
          threshold: matchSorter.rankings.CONTAINS,
        })
      : providers;
  }, [providers, query]);

  const { t } = useTranslation();
  const searchContent = t('search') as SearchContentType;

  const renderContent = useMemo(() => {
    if (isFetching) {
      return Array.from({ length: 12 }).map((_, i) => (
        <DivLoader
          key={`provider-loader-${i}`}
          className="h-[7.25rem] w-full bg-bgr-lighter lg:w-full dark:bg-bgr-lighter"
        />
      ));
    }

    if (!filteredProviders || filteredProviders?.length === 0) {
      return (
        <div className="col-span-full text-center">
          <EmptyState
            customSize={48}
            iconName="magnify"
            className="border-transparent p-2 !py-8"
            stylingOptions={{
              imageWrapper: 'mb-2',
            }}
            title={searchContent?.empty_state?.title}
            description={searchContent?.empty_state?.description}
          />
        </div>
      );
    }

    return <FilteredProvidersList filteredProviders={filteredProviders} />;
  }, [
    filteredProviders,
    isFetching,
    searchContent?.empty_state?.description,
    searchContent?.empty_state?.title,
  ]);

  return (
    <div
      className={cn([
        'mb-spacing-md grid grid-cols-3 gap-2 md:grid-cols-6 2xl:grid-cols-8',
        filteredProviders.length === 0 && 'pt-0',
        !isFetching &&
          filteredProviders?.length > 0 &&
          'md:gap-spacing-sm lg:mb-spacing-lg',
      ])}
    >
      {renderContent}
    </div>
  );
};

export default ProvidersResults;
