import React, { useMemo } from 'react';
import { GameInfo } from '@/types/games';
import { useGamesInfo } from '@/hooks/games';
import { FilteredGame } from '@/types/games_filters';
import { cn } from '@/utils/style';

interface Props {
  game?: FilteredGame;
}

const InfoTooltip = ({ game }: Props) => {
  const { data } = useGamesInfo();

  const foundGame = useMemo(
    () =>
      //@ts-ignore
      data?.filter((item: GameInfo) => item?.identifier === game?.identifier),
    [data, game?.identifier],
  );

  return (
    <div className="relative h-full overflow-hidden rounded-lg bg-bgr-hovered p-3">
      <div className="[&>span]:first-of-type: mb-2 flex items-center justify-between space-x-20">
        <span className="text-sm font-medium text-text-default">RTP:</span>
        <span
          className={cn([
            'text-base-primary',
            (!foundGame || !foundGame[0]?.payout) && `text-text-secondary`,
          ])}
        >
          {foundGame && foundGame[0]?.payout
            ? foundGame[0]?.payout + '%'
            : 'N/A'}
        </span>
      </div>
      <div>
        <span className="text-sm font-medium text-text-default">Paylines</span>
        <span className="text-text-secondary">
          {foundGame && foundGame[0]?.lines ? foundGame[0]?.lines : 'N/A'}
        </span>
      </div>
      <div>
        <span className="text-sm font-medium text-text-default">Provider:</span>
        <span className="capitalize text-text-secondary">{game?.provider}</span>
      </div>
    </div>
  );
};

export default InfoTooltip;
