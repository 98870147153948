import React, { useEffect, useMemo } from 'react';
import SearchInputField from './search-input-field';
import SearchFilters from './filters/filters-wrapper';
import { useAppDispatch, useAppSelector } from '@/hooks/redux';
import { SearchType } from '../types';
import ProviderResults from './provider-results';
import GameResults from './games-results/game-results';
import { Transition } from '@headlessui/react';
import FilterOptions from './filters/filter-options';
import { usePathname, useSearchParams } from 'next/navigation';
import {
  setQuery,
  setSelectedProviders,
  setSelectedSort,
} from '@/app/reducers/search';
import { cn } from '@/utils/style';

const SearchWrapper = () => {
  const searchParams = useSearchParams();
  const { searchType, expandFilter, selectedSort, selectedProviders, query } =
    useAppSelector((state) => state.search);
  const dispatch = useAppDispatch();
  const pathname = usePathname();
  const onSearchPage = pathname?.includes('/search');

  const renderResults = useMemo(() => {
    return searchType === SearchType.GAMES ? (
      <GameResults />
    ) : (
      <ProviderResults />
    );
  }, [searchType]);

  // reset when collapsing the filters
  useEffect(() => {
    if (
      !expandFilter &&
      (selectedProviders?.length > 0 || selectedSort !== 'novelty')
    ) {
      dispatch(setSelectedSort('novelty'));
      dispatch(setSelectedProviders([]));
    }
  }, [dispatch, expandFilter, selectedProviders?.length, selectedSort]);

  return (
    <div className="flex flex-col gap-y-spacing-md lg:gap-y-spacing-lg">
      <div
        className={cn([
          'flex flex-col gap-y-spacing-xs',
          onSearchPage && 'xl:hidden',
        ])}
      >
        <SearchInputField />
      </div>
      <SearchFilters />
      <Transition
        show={expandFilter && searchType === SearchType.GAMES}
        enter={`transition-transform ease-out duration-200`}
        enterFrom={`opacity-0 transform-gpu -translate-y-1`}
        enterTo={`opacity-100 transform-gpu translate-y-0`}
        leave={`transition-transform ease-in duration-150`}
        leaveFrom={`opacity-100 transform-gpu translate-y-0`}
        leaveTo={`opacity-0 transform-gpu -translate-y-1`}
      >
        <FilterOptions />
      </Transition>
      {renderResults}
    </div>
  );
};

export default SearchWrapper;
