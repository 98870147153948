import React, { useCallback } from 'react';
import { FilteredGame } from '@/types/games_filters';
import useShouldFillProfile from '@/hooks/use-should-fill-profile';
import { useAuth } from '@/hooks/auth';
import {
  useAddToFavorites,
  useFavoriteGamesData,
  useRemoveFromFavorites,
} from '@/hooks/games/favorites';
import useModal from '@/hooks/modal';
import Link from 'next/link';
import { GameCard } from '../GameCard';
import InfoTooltip from './info-tooltip';
import PlaySVG from 'public/assets/play-new.svg';
import useIsRestricted from '@/hooks/games/use-is-restricted';
import { useAppDispatch, useAppSelector } from '@/hooks/redux';
import { setOpenSearch, setQuery, setResults } from '@/app/reducers/search';
import { FALLBACK_SRC_BASE } from '../GameCard/constants';
import { isJackpot } from './utils';
import { cn } from '@/utils/style';

const GameItem: React.FC<{ game: FilteredGame; as?: 'li' | 'div' | 'span' }> =
  React.memo(({ game, as = 'li' }) => {
    const shouldFillProfile = useShouldFillProfile(game?.provider);
    const {
      user: { isAuthenticated },
    }: any = useAuth();
    const { mutateAsync: addToFavorites, isPending: isAddingToFavorites } =
      useAddToFavorites();
    const {
      mutateAsync: removeFromFavorites,
      isPending: isRemovingFromFavorites,
    } = useRemoveFromFavorites();
    const searchOpen = useAppSelector((state) => state.search.open);
    const dispatch = useAppDispatch();

    const isGameJackpot = isJackpot(game?.identifier);

    const { isRestricted } = useIsRestricted(game?.provider);
    const isRestrictedUpdated = isGameJackpot || isRestricted;

    const { favoriteGameIdentifiers } = useFavoriteGamesData();
    const { open } = useModal();
    const isMutating = isAddingToFavorites || isRemovingFromFavorites;

    const handleOnFavoriteClick = useCallback(() => {
      if (!favoriteGameIdentifiers?.includes(game.identifier)) {
        addToFavorites({
          gameID: game.id,
          completeGame: game,
        });
      } else {
        removeFromFavorites({
          gameID: game.id,
          completeGame: game,
        });
      }
    }, [addToFavorites, favoriteGameIdentifiers, game, removeFromFavorites]);

    const handleClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
      if (isRestrictedUpdated) {
        e.preventDefault();
      }

      if (
        !isAuthenticated &&
        (game?.categories?.includes('live-casino') ||
          game?.categories?.includes('live'))
      ) {
        e.preventDefault();
        open('signin');
        return;
      }
      if (shouldFillProfile) {
        e.preventDefault();
        open('fill-profile');
        return;
      }
    };

    const handleGameCardClick = () => {
      if (searchOpen && !isRestrictedUpdated) {
        dispatch(setOpenSearch(false));
        dispatch(
          setResults({
            pages: [],
            pageParams: [],
          }),
        );
        dispatch(setQuery(''));
      }
    };

    const Component = as;

    return (
      <Component key={`game-${game.slug}`}>
        <Link
          href={`/games/${game.slug}`}
          passHref
          prefetch={false}
          onClick={handleClick}
        >
          <GameCard
            identifier={game?.identifier}
            tag={game?.payout}
            provider={game?.provider}
            info={<InfoTooltip game={game} />}
            isFavorited={favoriteGameIdentifiers.includes(game.identifier)}
            hideFavoriteButton={!isAuthenticated}
            onFavoriteClick={handleOnFavoriteClick}
            isDisabled={isMutating}
            imageUrl={`https://cdn.wild.io/thumbnail/${game?.identifier}.png`}
            fallBackUrl={`${FALLBACK_SRC_BASE}/thumbnail/${
              game?.identifier.split('/')[0]
            }/${game?.identifier.split('/')[1]}.webp`}
            title={game.title}
            playIcon={<PlaySVG />}
            onClick={handleGameCardClick}
          />
        </Link>
      </Component>
    );
  });

GameItem.displayName = 'GameListItem';

export default GameItem;
