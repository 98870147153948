import { SearchType } from './types';

export const getModalTitleBySearchType = (searchType: SearchType) => {
  switch (searchType) {
    case SearchType.GAMES:
      return 'Games';
    case SearchType.PROVIDERS:
      return 'Providers';
  }
};

export const getSearchTypeById = (id: string) => {
  switch (id) {
    case 'providers':
      return SearchType.PROVIDERS;
    case 'games':
      return SearchType.GAMES;

    default:
      return SearchType.GAMES;
  }
};
