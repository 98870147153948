import { useLocalStorage } from 'usehooks-ts';

type SearchTerm = string;

function useSearchTerms() {
  const [searchTerms, setSearchTerms] = useLocalStorage<SearchTerm[]>(
    'searchTerms',
    [],
  );

  const addSearchTerm = (term: SearchTerm) => {
    if (!searchTerms.includes(term)) {
      const updatedTerms = [...searchTerms, term];
      if (updatedTerms.length > 5) {
        updatedTerms.shift();
      }
      setSearchTerms(updatedTerms);
    }
  };

  const removeSearchTerm = (term: SearchTerm) => {
    setSearchTerms(searchTerms.filter((t) => t !== term));
  };

  const clearSearchTerms = () => {
    setSearchTerms([]);
  };

  return {
    searchTerms,
    addSearchTerm,
    removeSearchTerm,
    clearSearchTerms,
  };
}

export default useSearchTerms;
