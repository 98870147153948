import {
  setSelectedCollection,
  setSelectedProviders,
  setSelectedSort,
} from '@/app/reducers/search';
import { useProviders } from '@/hooks/providers';
import { useAppDispatch, useAppSelector } from '@/hooks/redux';
import NoveltySelect from '@/modules/category-page/components/novelty-select';
import ProviderSelect from '@/modules/category-page/components/provider-select';
import { formatProviders } from '@/modules/category-page/utils';
import { cn } from '@/utils/style';
import React, { useCallback, useMemo } from 'react';

const FilterOptions = () => {
  const dispatch = useAppDispatch();
  const { selectedProviders, selectedSort, results, selectedCollection } =
    useAppSelector((state) => state.search);

  const { data: providers = [], isLoading: areProvidersLoading } =
    useProviders();

  const formattedProviders = useMemo(
    () => formatProviders(providers),
    [providers],
  );

  const handleProviderSelect = useCallback(
    (provider: any) => {
      const providerId = provider.id;
      if (selectedProviders.includes(providerId)) {
        dispatch(
          setSelectedProviders(
            selectedProviders.filter((pKey) => pKey !== providerId),
          ),
        );
      } else {
        dispatch(setSelectedProviders([...selectedProviders, providerId]));
      }
    },
    [dispatch, selectedProviders],
  );

  const handleSortBy = useCallback(
    (item: any) => {
      let gameCollection = selectedCollection?.includes('new')
        ? ['fresh-releases']
        : selectedCollection;

      if (
        item?.id &&
        item?.id !== 'novelty' &&
        !selectedCollection?.includes('all')
      ) {
        dispatch(setSelectedCollection([...gameCollection, 'all']));
      }

      dispatch(setSelectedSort(item?.id));
    },
    [dispatch, selectedCollection],
  );

  return (
    <div
      className={cn([
        'hide-scroll-wrapper flex w-full flex-wrap gap-1',
        !areProvidersLoading && results?.pages?.length === 0,
      ])}
    >
      <NoveltySelect selected={selectedSort} onSelect={handleSortBy} />
      <ProviderSelect
        providers={formattedProviders}
        selectedItems={selectedProviders.map((provider) => ({
          id: provider,
          value: provider,
          label: provider,
        }))}
        onSelect={handleProviderSelect}
      />
    </div>
  );
};

export default FilterOptions;
