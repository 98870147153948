import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useRouter, useSearchParams } from 'next/navigation';
import { useAppDispatch, useAppSelector } from '@/hooks/redux';
import { Field, Input, Radio, Select } from '@purposeinplay/core-v2';
import { cn } from '@/utils/style';
import { setOpenSearch, setQuery, setSearchType } from '@/app/reducers/search';
import { usePathname } from 'next/navigation';
import useDebounce from '@/hooks/debounce';
import MagnifySVG from 'public/assets/magnify.svg';
import { useTranslation } from '@/app/i18n/client';
import { SearchContentType } from '@/types/json-content/search';
import { useAnalytics } from '@/hooks/use-analytics';
import useSearchTerms from '../hooks/use-search-terms';
import { SearchType, SearchTypeItem } from '../types';
import { useSearchInputHandler } from '../hooks/use-search-input-handler';
import { getSearchTypeById } from '../utils';
import { createQueryString } from '@/utils/helpers';

const SearchInputField: React.FC<{
  forPage?: boolean;
  className?: string;
}> = ({ className, forPage = false }) => {
  const searchParams = useSearchParams();
  const pathname = usePathname();
  const dispatch = useAppDispatch();
  const { query, searchType } = useAppSelector((state) => state.search);
  const router = useRouter();
  const debounce = useDebounce();
  const [inputValue, setInputValue] = useState<string>(query);
  const { addSearchTerm } = useSearchTerms();
  const onSearchPage = pathname?.includes('search');

  const { onFocus, onBlur } = useSearchInputHandler();

  const { track } = useAnalytics();

  const { t } = useTranslation();
  const searchContent = t('search') as SearchContentType;

  useEffect(() => {
    // Update input value when the query changes
    setInputValue(query);
  }, [query]);

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value;

      setInputValue(value);
      debounce(() => {
        dispatch(setQuery(value));
        if (value?.length > 3) addSearchTerm(value);
      }, 500);
      if (onSearchPage)
        router.push(
          `${pathname}?${createQueryString('q', value, searchParams)}`,
        );
    },
    [
      addSearchTerm,
      debounce,
      dispatch,
      onSearchPage,
      pathname,
      router,
      searchParams,
    ],
  );

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (
      searchType === SearchType.PROVIDERS &&
      !pathname?.includes('providers')
    ) {
      router.push(`/providers?${createQueryString('q', query, searchParams)}`);
      dispatch(setOpenSearch(false));
      return;
    }

    track('game_searched', {
      category: 'games',
      label: query,
    });

    if (query.length > 3 && !pathname?.includes('search')) {
      addSearchTerm(query);
      router.push(`/search?${createQueryString('q', query, searchParams)}`);
      dispatch(setOpenSearch(false));
    }
  };
  const { search, games, providers } =
    searchContent?.input_field?.placeholder || {};
  const secondPart = searchType === SearchType.PROVIDERS ? providers : games;

  const inputFieldPlaceholder = `${search} ${secondPart}`;

  const getSelectPlaceholder = useMemo(() => {
    const { games, providers } = searchContent?.input_field?.placeholder || {};

    return searchType === SearchType.PROVIDERS ? providers : games;
  }, [searchContent?.input_field?.placeholder, searchType]);

  const handleSearchTypeChange = (item: SearchTypeItem) => {
    dispatch(setQuery(''));
    dispatch(setSearchType(getSearchTypeById(item?.id)));
  };

  const searchTypes = useMemo(() => {
    return [
      {
        id: 'games',
        component: (
          <div className="flex w-full flex-row items-center justify-between">
            <span className="max-w-36 truncate">{games}</span>
            <Radio
              readOnly
              className="pointer-events-none"
              id="games"
              checked={searchType === SearchType.GAMES}
            />
          </div>
        ),
      },
      {
        id: 'providers',
        component: (
          <div className="flex w-full flex-row items-center justify-between">
            <span className="max-w-36 truncate">{providers}</span>
            <Radio
              readOnly
              className="pointer-events-none"
              id="providers"
              checked={searchType === SearchType.PROVIDERS}
            />
          </div>
        ),
      },
    ];
  }, [games, providers, searchType]);

  return (
    <div className="w-full">
      <form
        className={cn([
          '[&>div[data-headlessui-state]]:[&>div>div]:w-full [&>div[data-headlessui-state]]:[&>span]:absolute [&>div[data-headlessui-state]]:[&>span]:inset-0',
          className,
        ])}
        id="search-form"
        autoComplete="off"
        onSubmit={handleSubmit}
      >
        <div className={cn([searchType === SearchType.PROVIDERS && 'mb-0'])}>
          <Field>
            <div className="relative">
              <Input
                id="search-input"
                required
                type="text"
                variant="static"
                leadingIcon={
                  <MagnifySVG className="h-5 w-5 !fill-none stroke-text-subdued" />
                }
                onFocus={onFocus}
                onBlur={onBlur}
                placeholder={inputFieldPlaceholder}
                value={inputValue}
                onChange={handleChange}
              />
              <div className="absolute right-4 top-1/2 z-50 inline-block -translate-y-1/2 transform-gpu">
                <Select
                  dropdownStylingOptions={{
                    mainWrapperStyles: 'max-w-max',
                    triggerStyles:
                      '[&>div]:!bg-transparent [&>div]:!border-none [&>div]:!ring-0 [&>div]:!px-0 [&_.radio-wrapper]:hidden',
                    containerStyles: 'min-w-[10rem] -right-4 mt-1.5 left-auto',
                  }}
                  placeholder={getSelectPlaceholder}
                  onSelect={handleSearchTypeChange}
                  items={searchTypes}
                />
              </div>
            </div>
          </Field>
        </div>
      </form>
    </div>
  );
};

export default SearchInputField;
