import { getUserPlatform } from '@/utils/device';
import React from 'react';
import { cn } from '@/utils/style';

const FieldIcon = ({ hasCloseBtn = true }: { hasCloseBtn?: boolean }) => {
  const { isMac } = getUserPlatform();

  return (
    <div
      className={cn([
        'absolute right-1 top-2 z-10 mr-1 hidden text-sm opacity-60 md:flex md:flex-row md:items-center md:space-x-1',
        !hasCloseBtn && 'right-2.5 top-2.5',
      ])}
    >
      <span>{isMac ? '⌘K' : 'CTRL+K'}</span>
    </div>
  );
};

export default FieldIcon;
