'use client';
import React from 'react';
import { Text } from '@purposeinplay/core-v2';
import { cn } from '@/utils/style';
import AdaptiveImage from '../AdaptiveImage';
import { DEFAULT_IMAGE_LOADER } from '@/utils/constants';

const EmptyState = ({
  iconName = 'target',
  title,
  description,
  className,
  button,
  customSize,
  stylingOptions,
}: {
  iconName?: string;
  title: string;
  description: string;
  className?: string;
  button?: React.ReactNode;
  customSize?: number;
  stylingOptions?: {
    imageWrapper?: string;
    image?: string;
    title?: string;
    description?: string;
    buttonWrapper?: string;
    wrapper?: string;
  };
}) => {
  return (
    <div
      className={cn([
        'flex flex-col items-center justify-center rounded-2xl border border-dashed border-border-general-default  px-4 py-8 text-center',
        className,
      ])}
    >
      <div
        className={cn([
          'flex max-w-[25rem] flex-col items-center justify-center',
          stylingOptions?.wrapper && stylingOptions?.wrapper,
        ])}
      >
        <div className={cn(['mb-3 shrink-0', stylingOptions?.imageWrapper])}>
          <AdaptiveImage
            alt="Empty state"
            loader={DEFAULT_IMAGE_LOADER}
            fill={false}
            width={customSize ? customSize : 64}
            height={customSize ? customSize : 64}
            className={stylingOptions?.image}
            sources={{
              desktop: {
                light: `/assets/empty-states/${iconName}-light.svg`,
                dark: `/assets/empty-states/${iconName}.svg`,
              },
            }}
          />
        </div>

        <h4
          className={cn([
            'mb-1 text-base font-semibold leading-4 text-text-default',
            stylingOptions?.title,
          ])}
        >
          {title}
        </h4>
        <Text
          size="sm"
          className={cn([
            'font-normal text-text-subdued',
            stylingOptions?.description,
          ])}
        >
          {description}
        </Text>
        {button && (
          <div className={cn(['mt-4', stylingOptions?.buttonWrapper])}>
            {button}
          </div>
        )}
      </div>
    </div>
  );
};

export default EmptyState;
