import React from 'react';

import { GamesListProps } from './types';
import GameItem from './game-item';
import { cn } from '@/utils/style';

const GamesList = React.memo(
  ({ pages, className, testId }: GamesListProps): JSX.Element => {
    return (
      <>
        {pages?.map((page, index) => (
          <ul
            data-testid={testId}
            key={`page-games-${index}`}
            data-page-id={page?.pagination?.current_page}
            className={cn([
              'grid grid-cols-3 gap-2 pt-spacing-sm  sm:grid-cols-5 md:first:pt-spacing-lg lg:grid-cols-8',
              className,
            ])}
          >
            {page?.data?.map((game) => <GameItem key={game.id} game={game} />)}
          </ul>
        ))}
      </>
    );
  },
);

GamesList.displayName = 'GamesList';

export default GamesList;
