import { cn } from '@/utils/style';
import React from 'react';

export const ProviderNameFallback: React.FC<{
  name: string;
  forModal?: boolean;
}> = ({ name, forModal }) => (
  <div
    data-testid={`provider-fallback-name-${name}`}
    className={cn([
      'flex h-8 w-[8.25rem] items-center justify-center text-xl font-semibold capitalize text-text-default',
      forModal && 'h-auto w-auto text-xs',
    ])}
  >
    {name}
  </div>
);
