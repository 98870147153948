import React from 'react';
import { cn } from '@/utils/style';
import { setOpenSearch, setQuery } from '@/app/reducers/search';
import { useAppDispatch } from '@/hooks/redux';
import { ProviderBadge } from './providers-badge';
import { ProviderCardProps } from '../types';
import ProviderCardBody from './providers-card-body';

const ProviderCard: React.FC<ProviderCardProps> = ({ provider, forModal }) => {
  const dispatch = useAppDispatch();

  const handleCardClick = () => {
    if (forModal) {
      dispatch(setOpenSearch(false));
      dispatch(setQuery(''));
    }
  };

  const gamesCount = provider?.games_count;

  return (
    <div
      data-testid={`provider-card-${provider.id}`}
      onClick={handleCardClick}
      className={cn([
        'flex cursor-pointer flex-col items-center justify-center overflow-hidden rounded-xl bg-bgr-light p-7 hover:bg-bgr-lighter',
        forModal && 'rounded-2xl p-2 md:p-3',
      ])}
    >
      <div
        className={cn([
          'relative flex h-[32px] w-[132px] flex-col items-center justify-between [&>img]:-indent-100',
          forModal && 'h-10 items-center justify-center',
        ])}
      >
        <ProviderCardBody provider={provider} forModal={forModal} />
      </div>

      <ProviderBadge forModal={forModal} count={gamesCount} />
    </div>
  );
};

export default ProviderCard;
